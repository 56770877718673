import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'
import {
    Currency,
    Reservation,
    ReservationStatuses,
    ReservationStatusesFromAPI,
} from './types'

export function isBeforeDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  
    const aYear = a.year();
    const aMonth = a.month();
  
    const bYear = b.year();
    const bMonth = b.month();
  
    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;
  
    if (isSameYear && isSameMonth) return a.date() < b.date();
    if (isSameYear) return aMonth < bMonth;
    return aYear < bYear;
}

export function isInclusivelyAfterDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(a, b);
}

export function isFriday(date) {
    const dayOfWeek = moment(date).isoWeekday()
    return dayOfWeek == 5
}

export function getDefaultDateAndTime() {
    moment.tz.setDefault('Europe/Prague')

    const currentTimeInCET = moment()

    let defaultTime = '00:01'
    let defaultDate = moment()

    if (currentTimeInCET.isAfter(moment().set({ hour: 5, minute: 40 }))) {
        defaultTime = '06:00'
    }
    if (currentTimeInCET.isAfter(moment().set({ hour: 11, minute: 40 }))) {
        defaultTime = '12:00'
    }
    if (currentTimeInCET.isAfter(moment().set({ hour: 17, minute: 40 }))) {
        defaultTime = '18:00'
    }
    if (currentTimeInCET.isAfter(moment().set({ hour: 23, minute: 40 }))) {
        defaultTime = '00:01'
        defaultDate.add(1, 'days')
    }

    return {
        defaultDate,
        defaultTime,
    }
}

export function getSearchParams() {
    const {defaultDate, defaultTime} = getDefaultDateAndTime()

    const domNode = document.getElementById('react-garage-detail-search')
    const serviceBeginsAtDate = domNode?.getAttribute('reservation-begins-at-date')
    const serviceBeginsAtTime = domNode?.getAttribute('reservation-begins-at-time')
    const serviceEndsAtDate = domNode?.getAttribute('reservation-ends-at-date')
    const serviceEndsAtTime = domNode?.getAttribute('reservation-ends-at-time')

    const searchParams = new URLSearchParams(document.location.search)

    const variantA = 'map_search'
    const variantB = 'reservation_step_reservation_time'
    
    const paramLocation =     searchParams.get(variantA+'[location]')       || searchParams.get(variantB+'[location]')       || null
    const paramBeginsAtDate = searchParams.get(variantA+'[begins_at_date]') || searchParams.get(variantB+'[begins_at_date]') || serviceBeginsAtDate || defaultDate.format('YYYY-MM-DD')
    const paramBeginsAtTime = searchParams.get(variantA+'[begins_at_time]') || searchParams.get(variantB+'[begins_at_time]') || serviceBeginsAtTime || defaultTime
    const paramEndsAtDate =   searchParams.get(variantA+'[ends_at_date]')   || searchParams.get(variantB+'[ends_at_date]')   || serviceEndsAtDate   || defaultDate.clone().add(1, 'days').format('YYYY-MM-DD')
    const paramEndsAtTime =   searchParams.get(variantA+'[ends_at_time]')   || searchParams.get(variantB+'[ends_at_time]')   || serviceEndsAtTime   || defaultTime

    return {
        paramLocation,
        paramBeginsAtDate,
        paramBeginsAtTime,
        paramEndsAtDate,
        paramEndsAtTime,
    }
}

export function getReservationLength(paramBeginsAtDate, paramBeginsAtTime, paramEndsAtDate, paramEndsAtTime): number {

    const normalisedBeginsAtTime = paramBeginsAtTime === '00:01' ? '00:00' : paramBeginsAtTime
    const normalisedEndsAtTime = paramEndsAtTime === '00:01' ? '00:00' : paramEndsAtTime
    
    const startMoment = moment(paramBeginsAtDate + " " + normalisedBeginsAtTime, ['DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm'])
    const endMoment = moment(paramEndsAtDate + " " + normalisedEndsAtTime, ['DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm'])

    const reservationLengthHours = endMoment.diff(startMoment, 'hours')

    return reservationLengthHours
}

export function getCurrency(): Currency {
    const domNode = document.getElementById('react-garages')
    const currency = domNode?.getAttribute('currency')

    return currency === Currency.eur ? Currency.eur : Currency.czk
}

export function getDayLimits(): {
    maximumStayDays: number,
    maximumDaysInAdvance: number,
} {
    let maximumStayDays = 30
    let maximumDaysInAdvance = 180
    
    const domNodes = [
        document.getElementById('react-garages'),
        document.getElementById('react-garage-detail-search'),
        document.getElementById('react-home-search'),
    ]

    for (const domNode of domNodes) {
        if (domNode) {
            if (domNode?.getAttribute('maximum-stay-days')) {
                //@ts-ignore
                maximumStayDays =  parseInt(domNode.getAttribute('maximum-stay-days'))
            }
            if (domNode?.getAttribute('maximum-days-in-advance')) {
                //@ts-ignore
                maximumDaysInAdvance =  parseInt(domNode.getAttribute('maximum-days-in-advance'))
            }
        }
    }

    return {
        maximumStayDays,
        maximumDaysInAdvance,
    }
}

export function getReservationStatus(state, beginsAt, endsAt) {
    if (state === ReservationStatusesFromAPI.booked && moment() > moment(endsAt)) {
        return ReservationStatuses.elapsed
    } else if (state === ReservationStatusesFromAPI.booked && moment() < moment(beginsAt)) {
        return ReservationStatuses.booked
    } else if (state === ReservationStatusesFromAPI.booked && moment() > moment(beginsAt) && moment() < moment(endsAt)) {
        return ReservationStatuses.current
    } else {
        return ReservationStatuses.canceled
    }
}

export function getReservationLinkNextDay(reservation: Reservation, path: string): string {
    
    const startDate = moment(reservation.startDate).add(1, 'days').format('YYYY-MM-DD')
    const startTime = moment(reservation.startDate).format('HH:mm').replace('00:00', '00:01')
    const endDate = moment(reservation.endDate).add(1, 'days').format('YYYY-MM-DD')
    const endTime = moment(reservation.endDate).format('HH:mm').replace('00:00', '00:01')

    const reservationLinkNextDay = `/${path
        }?reservation_step_reservation_time%5Bbegins_at_date%5D=${
        startDate}&reservation_step_reservation_time%5Bbegins_at_time%5D=${
        startTime}&reservation_step_reservation_time%5Bends_at_date%5D=${
        endDate}&reservation_step_reservation_time%5Bends_at_time%5D=${
        endTime}&reservation_step_reservation_time%5Bgarage_id%5D=${
        reservation.garageId}`

    return reservationLinkNextDay
}

export function getReservationLinkSameGarage(reservation: Reservation, path: string): string {

    const reservationLinkSameGarage = `/${path
        }?reservation_step_reservation_time%5Bgarage_id%5D=${reservation.garageId}`

    return reservationLinkSameGarage
}
