import type {
    START_DATE,
    END_DATE,
} from './constants'
import type { Moment } from 'moment'

export type Garage = {
    id: number,
    name: string,
    amountCentsCzk: number | null,
    amountCentsHourlyCzk: number | null,
    amountCentsEur: number | null,
    amountCentsHourlyEur: number | null,
    height: number,
    distance: number,
    image: string,
    image2: string | undefined,
    available: boolean,
    lat: number,
    lng: number,
    minimumStayHours: number,
    allowed: boolean,
    checkLaterAvailability: boolean,
    lastPlacesAvailable: boolean,
}

export enum SortBy {
    distance = "distance",
    price = "price",
}

export type FiltersType = {
    minPrice: number | null,
    maxPrice: number | null,
    minHeightPoint: 1 | 2 | 3 | 4 | 5,
    sort: SortBy,
    showAvailable: boolean,
}

export type HoverPoint = {
    id: number,
    lat: number,
    lng: number,
}

type PointOrigin = {
    fromList?: boolean,
}

export type SelectedPoint = HoverPoint & PointOrigin

export type SearchType = {
    startDate: Moment | null,
    startTime: string | null,
    endDate: Moment | null,
    endTime: string | null,
    location: string | null,
    focusedInput: typeof START_DATE | typeof END_DATE,
}

export type TimeHighlight = {
    startTime: boolean,
    endTime: boolean,
}

export type GarageAvailability = {
    garageIds?: Array<number>,
    diff: number,
    link?: string,
    onClick?: () => void,
}

export enum DocumentTypes {
    ticket = "ticket",
    taxCorrection = "tax-correction",
}

export type Document = {
    id: number,
    data_file_name: string,
    url: string,
}

export type Gate = {
    id: number,
    gateClass: GateClass,
    isVisible: boolean,
    name: {
        cs: string | null,
        en: string | null,
        de: string | null,
        pl: string | null,
    },
}

export type GateConfirmation = {
    id: number | null,
    gateClass: GateClass | null,
}

export type Reservation = {
    id: number,
    status: ReservationStatuses,
    garageId: number,
    garageName: string,
    garagePlace: string,
    driverFullName: string,
    driverEmail: string,
    licensePlate: string,
    phoneNumber1: string,
    phoneNumber2: string | null,
    startDate: string,
    endDate: string,
    createdAt: string,
    price: number,
    token: string,
    unpaidUrl: string | null,
    unpaidAmount: {
        cents: number,
        currency: string,
    },
    entrance?: Gate,
    exit?: Gate,
    receptions?: Array<Gate>,
    invoices: Array<Document>,
    tickets: Array<Document>,
    invoiceCorrections: Array<Document>,
    creditNotes: Array<Document>,
    terms_of_services: Document,
    privacy_policy: Document,
    garage_tos: Document,
}

export enum ReservationStatuses {
    booked = "booked",
    current = "current",
    elapsed = "elapsed",
    canceled = "canceled",
}

export enum ReservationStatusFilters {
    all = "all",
    active = "active",
    elapsed = "elapsed",
    canceled = "canceled",
}

export enum ReservationStatusesFromAPI {
    booked = "booked",
    canceled = "canceled",
}

export enum ReservationsSortBy {
    id = "id",
    startAt = "startAt",
}

export type ReservationsFiltersType = {
    status: ReservationStatusFilters,
    sort: ReservationsSortBy,
}

export type ReservationForm = {
    phoneNumber1: string | null,
    phoneNumber2: string | null,
    numberPlate: string | null,
}

export enum GateClass {
    entrance = "GarageEntrance",
    exit = "GarageExit",
    reception = "ReceptionDoor",
}

export enum ExtensionQuery {
    request = "prolong_request",
    submit = "prolong_submit",
}

export type ReservationExtensionResponse = {
    query: ExtensionQuery,
    message: string | null,
    extensionPriceCents: number,
    url: string | undefined,
    errors: Array<string>,
}

export enum Currency {
    czk = "czk",
    eur = "eur",
}
