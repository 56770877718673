import React from 'react'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { useTranslation } from 'react-i18next'

export default function TimePickerMobile({
    timeType,
    handleSelect,
    value,
    timeHighlight,
} : {
    timeType: 'start' | 'end',
    handleSelect: Function,
    value: string | null,
    timeHighlight: boolean,
}) {

    const { t } = useTranslation()

    return (
        <FormControl sx={{width: '100%'}}>
            <FormLabel id={timeType+'Time-label'} htmlFor={timeType+'Time-button'}>
                {t('search.'+timeType)}
            </FormLabel>
            <Select
                value={value}
                onChange={(e, newValue) => handleSelect(e, newValue, timeType+'Time')}
                slotProps={{
                    root: {
                        sx: {
                            bgcolor: timeHighlight ? 'secondary.plainActiveBg' : 'white',
                            transition: '250ms background-color',
                        },
                    },
                    button: {
                        id: timeType+'Time-button',
                        'aria-labelledby': `${timeType}Time-label ${timeType}Time-button`,
                        sx: {
                            // legacy application stylesheet override
                            fontWeight: 'inherit',
                            '&:focus': {
                                boxShadow: 'none',
                                outline: 'none',
                            },
                            '&:hover': {
                                color: 'inherit',
                            },
                        }
                    },
                }}
            >
                <Option value="00:01">00:01</Option>
                <Option value="06:00">06:00</Option>
                <Option value="12:00">12:00</Option>
                <Option value="18:00">18:00</Option>
            </Select>
        </FormControl>
    )
}