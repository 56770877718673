import React from 'react'
import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'
import Radio from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'
import FormLabel from '@mui/joy/FormLabel'
import Sheet from '@mui/joy/Sheet'
import { useTranslation } from 'react-i18next'

export default function TimePickerDesktop({
    timeType,
    handleChange,
    value,
    timeHighlight,
} : {
    timeType: 'start' | 'end',
    handleChange: Function,
    value: string | null,
    timeHighlight: boolean,
}) {

    const { t } = useTranslation()

    return (
        <Box sx={{
            padding: '1.5rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <FormLabel
                id={timeType+'Time-label'}
                sx={{
                    mb: 2,
                    fontWeight: 'md',
                    fontSize: 'lg',
                    textAlign: 'center',
                }}
            >
                {t('search.'+timeType)}
            </FormLabel>
            <RadioGroup
                aria-labelledby={timeType+'Time-label'}
                value={value}
                onChange={e => handleChange(e, timeType+'Time')}
                size="lg"
                sx={{ gap: 1 }}
            >
                {['00:01', '06:00', '12:00', '18:00'].map((value) => (
                    <Sheet
                        key={value}
                        sx={{
                            py: 1,
                            px: 2,
                            borderRadius: '0.250rem',
                            boxShadow: 'sm',
                            bgcolor: timeHighlight ? 'secondary.plainActiveBg' : 'white',
                            transition: '250ms background-color',
                            
                        }}
                    >
                        <Radio
                            label={value}
                            overlay
                            disableIcon
                            value={value}
                            slotProps={{
                                label: ({ checked }) => ({
                                    sx: {
                                        fontWeight: 'md',
                                        fontSize: 'md',
                                        color: checked ? 'text.primary' : 'text.secondary',
                                    },
                                }),
                                action: ({ checked }) => ({
                                    sx: (theme) => ({
                                        ...(checked && {
                                        '--variant-borderWidth': '1px',
                                        '&&': {
                                            // && to increase the specificity to win the base :hover styles
                                            // @ts-ignore
                                            borderColor: theme.vars.palette.secondary[500],
                                            // @ts-ignore
                                            backgroundColor: theme.vars.palette.secondary[200],
                                        },
                                        }),
                                    }),
                                }),
                            }}
                        />
                    </Sheet>
                ))}
            </RadioGroup>
        </Box>
    )
}